<template>
      <NavBar /> <!-- NavBar 컴포넌트 추가 -->
      <router-view />
</template>

<script>
import NavBar from './components/NavBar.vue'; // NavBar 컴포넌트 import

export default {
  name: 'App',
  components: {
    NavBar, // NavBar 등록
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
