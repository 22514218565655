<template>
    <div class="container">
        <img alt="Vue logo" src="../assets/images/smng-seolandra-logo.png" style="width: 300px; height: 300px;">
    </div>
    <div class="container">
        <!-- 버튼 영역 -->
        <div class="row">
            <div class="col text-left"> <!-- 버튼 위치를 왼쪽으로 조정 -->
                <p class="h3 mb">서미누기의 POE2 한국어 아이템 영어 번역기 v1.0</p>
                <!-- <p class="h5"><span class="text-warning">https 관련 설정이 마무리되면 사용하기가 더 쉬워질 수 있습니다. (현재 작업 중).</span></p> -->
                <p class="h5 mb-4"><span class="text-warning">번역되지 않은 텍스트는 라이브 방송 또는 디스코드 번역제보 채널에 이야기 부탁드려요!</span></p>
                <p class="h5 mb-4 text-start"><span class="text-primary"></span></p>
                <p class="h5 text-start"><span class="text-warning">사용 방법</span></p>
                <p class="text-start">순서 1. 인-게임에서 영어로 번역하고자 하는 <span class="text-info fw-bolder">아이템에 마우스를 올린 뒤 Ctrl+C를
                        눌러 아이템 정보를 복사한다.</span></p>
                <!-- <p class="text-start">순서 2. 번역할 텍스트에 Ctrl+V를 눌러 붙여넣기 하고, <button type="button" class="btn btn-xs btn-primary" disabled>번역</button> 버튼을 누른다.</p>
                    <p class="text-start">순서 3. 번역된 텍스트에 텍스트가 제대로 번역되었다면 <span class="text-success fw-bolder">텍스트 입력창에 마우스로 클릭 하고 Ctrl + A, Ctrl + V로 단축키로 텍스트를 복사한다.</span></p> -->
                <p class="text-start">순서 2. <button type="button" class="btn btn-xs btn-success" disabled>번역하기</button>
                    버튼을 누른다.</p>
                <!-- <p class="text-start">순서 3. <button type="button" class="btn btn-xs btn-success" disabled>번역하기</button> 버튼을 누른다.</p> -->
                <p class="text-start">순서 3. 영어로 번역된 아이템 정보를 <span class="text-danger fw-bolder">Items Tab → Create
                        Custom → Ctrl + V → Create</span> 추가해서 시뮬레이션한다.</p>

                <!-- 번역 버튼 -->
                <!-- <button @click="readyTranslateText" class="btn btn-danger mx-2">번역 준비</button>
                            <button @click="translateText" class="btn btn-primary mx-2">번역</button> -->
                <!-- 복사하기 버튼 -->
                <!-- <button @click="copyToClipboard" class="btn btn-success mx-2">복사</button> -->
                <button @click="executeTranslationFlow" class="btn btn-success mx-2 text-center">번역하기</button>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <CoupangPartners />
            </div>
        </div>
        <div class="row">
            <!-- 좌측 텍스트 영역: 입력 -->
            <div class="col-md-6">
                <div class="form-group">
                    <label for="inputTextArea" class="lead">번역할 텍스트</label>
                    <textarea v-model="inputText" class="form-control" id="inputTextArea" rows="30"
                        v-bind:readonly="false"></textarea>
                </div>
            </div>
            <!-- 우측 텍스트 영역: 출력 -->
            <div class="col-md-6">
                <div class="form-group">
                    <label for="outputTextArea" class="lead">번역된 텍스트</label>
                    <textarea v-model="outputText" class="form-control" id="outputTextArea" rows="30"></textarea>
                </div>
            </div>
        </div>
    </div>
    <footer class="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
        <div class="col-md-12">
            <p class="text-center">seominugi.com is not affiliated with or endorsed by Grinding Gear Games.</p>
        </div>
    </footer>
</template>

<script>
// 두 개의 JSON 파일을 임포트합니다.
import smngBasic from '@/assets/korean-poe-smng-data-basic.json';
import smngPoe2Basic from '@/assets/korean-poe2-smng-data-basic.json';
import smngCustom from '@/assets/korean-poe-smng-data-custom.json';
import items from '@/assets/korean-poe2-trade-data-items.json';
import stats from '@/assets/korean-poe2-trade-data-stats.json';
import unique from '@/assets/korean-poe2-trade-data-unique.json.json';
import itemSuffix from '@/assets/smng-poe-item-suffix.js';

import CoupangPartners from './CoupangPartners.vue';

export default {
    name: 'TranslateText',
    components: {
        CoupangPartners
    },
    mounted() {
        document.body.style.backgroundColor = '#1E2952';

        const pTags = document.querySelectorAll('p');
        const labelTags = document.querySelectorAll('label');

        pTags.forEach(tag => {
            tag.style.setProperty('color', '#FCE0CD', 'important');
        });

        labelTags.forEach(tag => {
            tag.style.setProperty('color', '#FCE0CD', 'important');
        });
    },
    data() {
        return {
            inputText: '', // 번역해야 할 텍스트
            outputText: '', // 번역된 텍스트
            translations: {}, // 번역에 참고할 json 데이터 객체
            itemSuffix: [] // 아이템 이름을 만드는데 필요한 객체
        };
    },
    created() {
        // 두 개의 JSON 데이터를 하나로 병합합니다.
        this.translations = { ...smngBasic, ...smngPoe2Basic, ...smngCustom, ...items, ...stats, ...unique };
        this.itemSuffix = [...itemSuffix];
    },
    methods: {
        async readyTranslateText() {
            try {
                const text = await navigator.clipboard.readText();
                const processedText = text.replace(/\r/g, "");
                const lines = processedText.split('\n');

                if (lines.length >= 1) {
                    const firstLine = lines[0].trim();

                    // 정규식을 사용하여 첫 번째 줄이 한국어인지 영어인지 판단
                    const koreanMatches = firstLine.match(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g);
                    const englishMatches = firstLine.match(/[a-zA-Z]/g);

                    const koreanCount = koreanMatches ? koreanMatches.length : 0;
                    const englishCount = englishMatches ? englishMatches.length : 0;

                    // console.log("koreanCount의 값은?" + koreanCount);
                    // console.log("englishCount의 값은?" + englishCount);
                    this.inputText = processedText;

                    if (koreanCount > englishCount) {
                        return 'ko';
                    } else if (englishCount > koreanCount) {
                        return 'en';
                    } else {
                        return 'unknown'; // 알 수 없는 언어
                    }
                }
            } catch (err) {
                console.error('클립보드에서 읽기 실패:', err);
                return 'error'; // 예외 발생 시 문자열 반환
            }
        },
        async translateKoreanText() {
            try {
                if (!this.inputText) {
                    alert("번역할 텍스트가 없습니다.");
                    return;
                }

                const lines = this.inputText.split('\n');

                // 1. 숫자 치환 및 복원 관련 헬퍼 함수들
                const replaceNumbersWithHash = (text) => {
                    const numbers = [];
                    // 정규식: 정수 또는 소수, 선택적으로 범위(~ 포함) 처리하며, 숫자 바로 뒤에 "당"이 오면 매칭하지 않음
                    const unifiedRegex = /([+-]?\d+(?:\.\d+)?(?:~[+-]?\d+(?:\.\d+)?)?)(?!당)/g;
                    const replacedText = text.replace(unifiedRegex, (match) => {
                        if (match.includes("~")) {
                            const [first, second] = match.split('~');
                            numbers.push({ value: first.trim() });
                            numbers.push({ value: second.trim() });
                            return "#~#";
                        } else {
                            numbers.push({ value: match });
                            return "#";
                        }
                    });
                    console.log("🔹 변환된 텍스트:", replacedText);
                    console.log("🔹 저장된 숫자 목록:", numbers);
                    return { replacedText, numbers };
                };

                const getNumValue = (num) => {
                    if (num && typeof num === "object" && "value" in num) {
                        return num.value;
                    }
                    return num;
                };

                const restoreNumbers = (template, numbers) => {
                    let idx = 0;
                    return template.replace(/(#~#|#)/g, (match) => {
                        if (match === "#~#") {
                            const first = idx < numbers.length ? getNumValue(numbers[idx]) : "";
                            const second = idx + 1 < numbers.length ? getNumValue(numbers[idx + 1]) : "";
                            idx += 2;
                            return `${first} to ${second}`;
                        } else {
                            const num = idx < numbers.length ? getNumValue(numbers[idx]) : "";
                            idx++;
                            return num;
                        }
                    });
                };

                const generateTextCases = (text) => {
                    const { replacedText, numbers } = replaceNumbersWithHash(text);
                    const cases = new Map();
                    const placeholderRegex = /(#~#|#)/g;
                    const tokens = replacedText.match(placeholderRegex) || [];

                    // 일반 "#"만 토글 대상
                    const togglableIndices = tokens.map((token, idx) => token === "#" ? idx : null).filter(idx => idx !== null);
                    const togglableCount = togglableIndices.length;
                    const totalCases = 1 << togglableCount;
                    console.log(`🔹 [generateTextCases] 원본 텍스트: ${text}`);
                    console.log(`🔹 [generateTextCases] 변환된 텍스트: ${replacedText}`);
                    console.log(`🔹 [generateTextCases] 저장된 숫자:`, numbers);
                    console.log(`🔹 [generateTextCases] 토글 가능한 placeholder 개수: ${togglableCount}, 생성할 경우의 수: ${totalCases}`);

                    for (let i = 0; i < totalCases; i++) {
                        let tempText = replacedText;
                        let tempNumbers = [];
                        let toggleIdx = 0; // 일반 "#" 토글 인덱스
                        let numIdx = 0;    // 전체 numbers 배열 인덱스

                        tempText = tempText.replace(placeholderRegex, (match) => {
                            if (match === "#~#") {
                                // 범위 placeholder는 토글하지 않고 항상 그대로 유지
                                if (numIdx + 1 < numbers.length) {
                                    tempNumbers.push(numbers[numIdx].value);
                                    tempNumbers.push(numbers[numIdx + 1].value);
                                }
                                numIdx += 2;
                                return "#~#";
                            } else { // match === "#"
                                if (i & (1 << toggleIdx)) {
                                    const val = numbers[numIdx].value;
                                    numIdx++;
                                    toggleIdx++;
                                    return val;
                                } else {
                                    tempNumbers.push(numbers[numIdx].value);
                                    numIdx++;
                                    toggleIdx++;
                                    return "#";
                                }
                            }
                        });
                        cases.set(tempText, tempNumbers);
                    }
                    console.log("🔹 [generateTextCases] 생성된 경우의 수:", Array.from(cases.keys()));
                    console.log("🔹 [generateTextCases] 각 경우의 수에 저장된 숫자 목록:", Array.from(cases.entries()));
                    return { cases };
                };

                // 2. 번역 처리용 분기별 함수들
                const translateRarityLine = (line, lines, translations) => {
                    const rarityPattern = /아이템 희귀도: (\S+)/;
                    const rarityMatch = lines[1].match(rarityPattern);
                    if (rarityMatch) {
                        const rarity = rarityMatch[1];
                        switch (rarity) {
                            case "일반":
                                console.log("노말 아이템");
                                return translations[line] || line;
                            case "마법": {
                                console.log("매직 아이템");
                                // "-"가 있으면 접두어와 접미어가 있는 경우, 기본 텍스트는 왼쪽 부분만 사용
                                let baseText = line.includes('-') ? line.split('-')[0].trim() : line.trim();

                                // 우선 전체 기본 텍스트로 번역 시도
                                let translatedBase = translateDefaultLine(baseText, translations);

                                // translateDefaultLine이 원본 텍스트 그대로 반환하면 번역 데이터가 없는 것으로 간주
                                if (translatedBase === baseText) {
                                    const words = baseText.split(" ");
                                    let foundTranslation = false;
                                    for (let i = 1; i < words.length; i++) {
                                        let candidate = words.slice(i).join(" ").trim();
                                        let candidateTranslation = translateDefaultLine(candidate, translations);
                                        if (candidateTranslation !== candidate) {
                                            translatedBase = candidateTranslation;
                                            foundTranslation = true;
                                            break;
                                        }
                                    }
                                    if (!foundTranslation) {
                                        translatedBase = "번역 데이터 없음";
                                    }
                                }
                                return translatedBase;
                            }
                            case "희귀":
                                console.log("희귀 아이템");
                                return "seoMinugi Rare Item";
                            case "고유":
                                console.log("고유 아이템");
                                return translations[line] || line;
                            default:
                                return "알 수 없는 희귀도";
                        }
                    }
                    return translations[line] || line;
                };

                const translateColonLine = (line, translations) => {
                    // "현재 충전량:"으로 시작하면 colon 분리하지 않고 전체 텍스트를 기본 번역 로직으로 처리
                    if (line.startsWith("현재 충전량:")) {
                        return translateDefaultLine(line, translations);
                    }

                    let [key, value] = line.split(':').map(part => part.trim());
                    console.log("🔹 key 체크:", key);
                    console.log("🔹 value 체크:", value);

                    if (key === "요구 사항") {
                        value = value.replace(/\(unmet\)/g, "").replace(/\s+/g, " ").trim();
                    }

                    const translatedKey = translations[key] || key;
                    let translatedValue;
                    if (value.includes(',')) {
                        const parts = value.split(',').map(part => part.trim());
                        const translatedParts = parts.map(part => {
                            try {
                                const parsedValue = JSON.parse(part);
                                return (parsedValue && parsedValue.value) ? parsedValue.value : parsedValue;
                            } catch (e) {
                                if (part.match(/[0-9]/)) {
                                    const { replacedText, numbers } = replaceNumbersWithHash(part);
                                    const translationTemplate = translations[replacedText] || replacedText;
                                    return restoreNumbers(translationTemplate, numbers);
                                } else {
                                    return translations[part] || part;
                                }
                            }
                        });
                        translatedValue = translatedParts.join(', ');
                    } else {
                        try {
                            const parsedValue = JSON.parse(value);
                            translatedValue = (parsedValue && parsedValue.value) ? parsedValue.value : parsedValue;
                        } catch (e) {
                            if (value.match(/[0-9]/)) {
                                const { replacedText, numbers } = replaceNumbersWithHash(value);
                                const translationTemplate = translations[replacedText] || replacedText;
                                translatedValue = restoreNumbers(translationTemplate, numbers);
                            } else {
                                translatedValue = translations[value] || value;
                            }
                        }
                    }
                    return `${translatedKey}: ${translatedValue}`;
                };

                const translateParenthesesLine = (line, translations) => {
                    const parenthesesMatch = line.match(/(.*?)\s*(\(.*?\))$/);
                    if (!parenthesesMatch) return line;
                    const textWithoutParentheses = parenthesesMatch[1].trim();
                    const parenthesesText = parenthesesMatch[2];
                    console.log("🔹 번역할 텍스트:", textWithoutParentheses);
                    console.log("🔹 괄호 내용 유지:", parenthesesText);
                    const { cases } = generateTextCases(textWithoutParentheses);
                    console.log("🔹 생성된 경우의 수:", Array.from(cases.keys()));
                    let matchingKey = null;
                    let numbersForCase = [];
                    for (const [caseText, numbers] of cases.entries()) {
                        if (translations[caseText]) {
                            matchingKey = caseText;
                            numbersForCase = numbers;
                            break;
                        }
                    }
                    if (!matchingKey && translations[textWithoutParentheses]) {
                        matchingKey = textWithoutParentheses;
                    }
                    let finalTranslatedText;
                    if (!matchingKey || !translations[matchingKey]) {
                        console.log("⚠️ [번역] 적절한 번역 데이터를 찾지 못함. 원본 유지.");
                        finalTranslatedText = textWithoutParentheses;
                    } else {
                        console.log(`✅ [번역] 번역 데이터 찾음: ${matchingKey} → ${translations[matchingKey]}`);
                        console.log("🔹 복원할 숫자들:", numbersForCase);
                        finalTranslatedText = restoreNumbers(translations[matchingKey], numbersForCase);
                    }
                    return `${finalTranslatedText} ${parenthesesText}`;
                };

                const translateCorruptedLine = (line, index, lines) => {
                    if (line.trim() === "타락" && index === lines.length - 1) {
                        return "Corrupted";
                    }
                    return null;
                };

                const translateDefaultLine = (line, translations) => {
                    const { cases } = generateTextCases(line);
                    console.log("🔹 [번역] 원본 텍스트:", line);
                    console.log("🔹 [번역] 생성된 경우의 수:", Array.from(cases.keys()));
                    let matchingKey = null;
                    let numbersForCase = [];
                    for (const [caseText, numbers] of cases.entries()) {
                        if (translations[caseText]) {
                            matchingKey = caseText;
                            numbersForCase = numbers;
                            break;
                        }
                    }
                    if (!matchingKey && translations[line]) {
                        matchingKey = line;
                    }
                    if (!matchingKey || !translations[matchingKey]) {
                        console.log("⚠️ [번역] 적절한 번역 데이터를 찾지 못함. 원본 유지.");
                        return line;
                    } else {
                        console.log(`✅ [번역] 번역 데이터 찾음: ${matchingKey} → ${translations[matchingKey]}`);
                        console.log("🔹 [번역] 복원할 숫자들:", numbersForCase);
                        const finalTranslation = restoreNumbers(translations[matchingKey], numbersForCase);
                        console.log("✅ [번역] 최종 번역된 텍스트:", finalTranslation);
                        return finalTranslation;
                    }
                };

                // 번역 전, 마지막 줄이 빈 문자열인 경우 제거
                if (lines.length && lines[lines.length - 1].trim() === '') {
                    lines.pop();
                }

                // 3. 최종 번역 처리: 각 줄별 조건에 따라 번역 수행
                const translatedLines = lines.map((line, index, array) => {
                    // 1. index 2: 아이템 희귀도 처리
                    if (index === 2) {
                        const rarityPattern = /아이템 희귀도: (\S+)/;
                        const rarityMatch = array[1].match(rarityPattern);
                        if (rarityMatch) {
                            return translateRarityLine(line, array, this.translations);
                        }
                    }
                    // 2. 콜론(:) 포함된 경우
                    else if (line.includes(':')) {
                        return translateColonLine(line, this.translations);
                    }
                    // 3. 괄호가 포함된 경우
                    else if (line.match(/(.*?)\s*(\(.*?\))$/)) {
                        return translateParenthesesLine(line, this.translations);
                    }
                    // 4. 마지막 줄 "타락" 처리
                    else if (line.trim() === "타락" && index === array.length - 1) {
                        return translateCorruptedLine(line, index, array);
                    }
                    // 5. 기본 처리
                    else {
                        return translateDefaultLine(line, this.translations);
                    }
                });

                // 번역 결과 적용
                this.outputText = translatedLines.join('\n');

                return true
            } catch (error) {
                console.error("번역 중 오류 발생:", error);
                alert("번역 중 오류가 발생했습니다.");
            }
        },
        translateTextPart(text) {
            let translatedText = text;
            // Object.prototype.hasOwnProperty를 사용하여 안전하게 속성 확인
            if (Object.prototype.hasOwnProperty.call(this.translations, translatedText)) {
                // 주어진 텍스트가 this.translations 객체의 키라면 해당 값을 반환
                return this.translations[text];
            } else {
                // 텍스트가 객체의 키가 아니라면 원본 텍스트를 그대로 반환
                return text;
            }
        },
        async copyToClipboard() {
            try {
                // "outputTextArea" 아이디를 가진 textarea 요소를 찾습니다.
                const textarea = document.getElementById('outputTextArea');
                if (!textarea) {
                    alert('복사할 텍스트가 포함된 요소를 찾을 수 없습니다.');
                    return;
                }
                // textarea의 텍스트를 가져옵니다.
                const textToCopy = textarea.value;
                if (textToCopy === '') {
                    alert('복사할 텍스트가 없습니다.');
                    return;
                }
                // 폴리필 라이브러리를 사용하여 클립보드에 텍스트를 복사합니다.
                // 여기서는 navigator.clipboard API를 직접 사용하는 것처럼 보이지만,
                // 폴리필이 적용되어 있으면 브라우저가 지원하지 않는 경우에도 작동합니다.
                navigator.clipboard.writeText(textToCopy).then(() => {
                    alert('클립보드에 복사되었습니다.');
                }, (err) => {
                    console.error('복사 실패:', err);
                    alert('복사에 실패했습니다. 콘솔을 확인해주세요.');
                });
                return true

            } catch (err) {
                console.error('클립 보드로 복사 중 실패:', err);
                alert('복사에 실패했습니다. 권한 문제를 확인해주세요.');

            }
        },
        async executeTranslationFlow() {
            const languageCode = await this.readyTranslateText();
            console.log("languageCode 값은?" + languageCode)
            if (languageCode === "ko") {
                const translated = await this.translateKoreanText();
                if (translated) {
                    await this.copyToClipboard();
                }
            } else if (languageCode === "en") {
                alert("영어 > 한국어는 구현 중입니다.")
            }
        }

    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
