/* eslint-disable no-undef */
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'vuetify/styles'; // Vuetify 3 스타일
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { createVuetify } from 'vuetify';
import VueTheMask from 'vue-the-mask';
import VueClipboard from 'vue-clipboard2';

// Google Analytics 스크립트를 로드하는 함수
function loadGAScript() {
    if (!window.gtag) {
        const script = document.createElement('script');
        script.async = true;
        script.src = 'https://www.googletagmanager.com/gtag/js?id=G-GXRCFE8KPM';
        document.head.appendChild(script);

        script.onload = () => {
            window.dataLayer = window.dataLayer || [];
            function gtag() { window.dataLayer.push(arguments); }
            window.gtag = gtag;
            gtag('js', new Date());
            gtag('config', 'G-GXRCFE8KPM');
        };
    }
}

// Vue 애플리케이션 생성
const app = createApp(App);
const vuetify = createVuetify(); // Vuetify 인스턴스 생성

// 플러그인 등록
app.use(vuetify);
app.use(router);
app.use(VueTheMask);
app.use(VueClipboard);

// Google Analytics를 페이지 전환 시 트래킹
router.afterEach((to) => {
    if (typeof window.gtag === 'function') {
        window.gtag('config', 'G-GXRCFE8KPM', {
            page_path: to.fullPath,
        });
    }
});

// Vue 애플리케이션 마운트
app.mount('#app');

// Google Analytics 스크립트 로드
loadGAScript();
