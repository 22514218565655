<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="container">
            <!-- 모바일 메뉴 토글 버튼 -->
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <!-- 네비게이션 메뉴 -->
            <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <li class="nav-item">
                        <router-link class="nav-link" to="/poe/item-translate">POE1 아이템 번역</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/poe2/item-translate">POE2 아이템 번역</router-link>
                    </li>
                </ul>

                <!-- 우측 외부 링크 아이콘 -->
                <ul class="navbar-nav ms-auto">
                    <li class="nav-item">
                        <a class="nav-link" target="_blank" href="https://cafe.naver.com/seominugi">
                            <img alt="Naver Cafe logo" src="../assets/images/naver_cafe_logo.png" class="icon-img">
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" target="_blank" href="https://www.youtube.com/@minugi">
                            <img alt="Youtube logo" src="../assets/images/yt_icon_rgb.png" class="icon-img">
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" target="_blank" href="https://discord.gg/kEm2G2qcZQ">
                            <img alt="Discord logo" src="../assets/images/icon_clyde_black_RGB.png" class="icon-img">
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    name: "NavBar"
};
</script>

<style scoped>
.navbar img.icon-img {
    height: 30px;
    /* 외부 링크 아이콘 크기 */
}
</style>