import { createRouter, createWebHistory } from 'vue-router';
import TranslateText from '@/components/TranslateText.vue';
import PoE2TranslateText from '@/components/PoE2TranslateText.vue';

const routes = [
    {
        path: '/',
        redirect: '/poe/item-translate'  // "/" 경로 리디렉션 처리
    },
    {
        path: '/poe/item-translate',
        name: 'TranslateText',
        component: TranslateText,
        meta: {
            title: '서미누기 - POE 한국어 아이템 정보 영어 번역기',
            metaTags: [
                {
                    name: 'description',
                    content: '서미누기의 POE 한국어 아이템 정보 영어 번역기입니다. 한국어 아이템 정보를 영어로 번역해보세요.'
                },
                {
                    property: 'og:title',
                    content: '서미누기 - POE 한국어 아이템 정보 영어 번역기'
                },
                {
                    property: 'og:description',
                    content: '서미누기의 POE 한국어 아이템 정보 영어 번역기입니다. 한국어 아이템 정보를 영어로 번역해보세요.'
                }
            ]
        }
    },
    {
        path: '/poe2/item-translate',
        name: 'PoE2TranslateText',
        component: PoE2TranslateText,
        meta: {
            title: '서미누기 - POE2 한국어 아이템 정보 영어 번역기'
        }
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach((to, from, next) => {
    document.title = to.meta.title || '서미누기';

    // 기존 메타 태그 삭제
    Array.from(document.querySelectorAll('meta[name], meta[property]')).forEach(el => el.remove());

    // 새로운 메타 태그 추가
    if (to.meta.metaTags) {
        to.meta.metaTags.forEach(tagDef => {
            const metaTag = document.createElement('meta');
            Object.keys(tagDef).forEach(key => {
                metaTag.setAttribute(key, tagDef[key]);
            });
            document.head.appendChild(metaTag);
        });
    }

    next();
});

export default router;
